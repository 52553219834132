import React from 'react';
import MessageList from '../Admin/Messages/MessageList';


function DisplayDonatePage (){

    return (
        <div style={{textAlign: "center", marginTop: "5rem"}}>
        <h4>Donation Page</h4>
        <MessageList/>
        <h1>✋🏽🛑</h1>
        <h3>Unfortunately, this page is under construction. 🔨</h3>
        <h2>Please try again later.</h2>
        </div>
    )
}

export default DisplayDonatePage;