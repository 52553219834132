import React from 'react';


function DisplayAboutUs (){

    return (
        <div style={{textAlign: "center", marginTop: "5rem"}}>
        <h4>Who We Are Page</h4>
        <h1>✋🏽🛑</h1>
        <h3>Unfortunately, this page is under construction. 🔨</h3>
        <h2>Please try again later.</h2>
        </div>
    )
}

export default DisplayAboutUs;